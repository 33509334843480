<template>
  <div class="container_margin">
    <!-- <PageLoader
      v-if="pageLoading || duration < 100"
      :loading="pageLoading"
      extra_msg="Collecting all the files"
      :duration="duration"
      :message="[
        'Generating Pre-Trial Case Flow',
        'Please wait a moment while we process it. This may take 3 to 4 minutes.',
      ]"
    /> -->
    <PageLoader
        v-if="pageLoading || duration < 100"
        :loading="pageLoading"
        extra_msg="Collecting all the files"
        :duration="duration"
        :message="pageLoaderMessage"
      />


    <div v-else>
      <div class="card border shadow-sm p-3 rounded">
        <h5 class="text-blue">
          <b>{{ "Case Info" }}</b>
        </h5>

        <div class="row mt-3">
          <div class="col-10">
            <b>{{ "Case" }}</b>
            <p>{{ client_case }}</p>
          </div>

          <div class="col-2">
            <b>{{ "Client Type" }}</b>
            <p>{{ client_type | capitalize }}</p>
          </div>
        </div>

        <template v-if="pre_event">
          <div class="row">
            <div class="col">
              <b>{{ "Background/Facts of The Case" }}</b>
              <p>{{ pre_event }}</p>
            </div>

            <div class="col-2" v-if="courtname === '' || courtname === 'all'">
              <b>{{ "Jurisdiction" }}</b>
              <p>{{ jurisdiction }}</p>
            </div>
          </div>

          <div class="row">
            <div class="col-3" v-if="courtname !== 'all' && courtname !== ''">
              <b>{{ "Jurisdiction" }}</b>
              <p>{{ jurisdiction }}</p>
            </div>

            <div class="col-4" v-if="courtname !== 'all' && courtname !== ''">
              <b>Court/Institution</b>
              <p>{{ courtname }}</p>
            </div>

            <div
              :class="
                courtname !== 'all' && courtname !== '' ? 'col' : 'col-3'
              "
              v-if="lawyer"
            >
              <b>Lawyer</b>
              <p class="mb-0">{{ lawyer }}</p>
            </div>

            <div class="col" v-if="judge">
              <b>Judge</b>
              <p class="mb-0">{{ judge }}</p>
            </div>
          </div>

          <div v-if="research_jurisdictions_courts.length">
            <p class="my-2 fw-bold">Research Jurisdiction(s) & Court(s)</p>
            <div v-for="(juris_court, idx) in research_jurisdictions_courts" :key="idx" class="row mb-1">
              <div class="col-3">
                <b>{{ "Jurisdiction" }}</b>
                <p class="mb-0">{{ juris_court[0] }}</p>
              </div>
  
              <div class="col-4">
                <b>Court/Institution</b>
                  <p v-for="(court, idx) in juris_court[1]" :key="idx" class="mb-0">{{ court }}</p>
              </div>
            </div>
          </div>
        </template>

        <template v-if="pre_event_file">
          <div class="row">
            <div class="col-3 d-flex flex-column align-items-start">
              <b>{{ "Background/Facts of The Case" }}</b>
              <button @click="openPDF" class="mt-1 btn btn-primary">
                Open File
              </button>
            </div>

            <div class="col-3">
              <b>{{ "Jurisdiction" }}</b>
              <p>{{ jurisdiction }}</p>
            </div>

            <div class="col" v-if="courtname.length">
              <b>Court/Institution</b>
              <p>
                {{
                  courtname == "all"
                    ? "All Courts and Institutions in " + jurisdiction
                    : courtname
                }}
              </p>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-3" v-if="lawyer">
              <b>Lawyer</b>
              <p class="mb-0">{{ lawyer }}</p>
            </div>

            <div class="col" v-if="judge">
              <b>Judge</b>
              <p class="mb-0">{{ judge }}</p>
            </div>
          </div>

          <div v-if="research_jurisdictions_courts.length">
            <p class="my-2 fw-bold">Research Jurisdiction(s) & Court(s)</p>
            <div v-for="(juris_court, idx) in research_jurisdictions_courts" :key="idx" class="row mb-1">
              <div class="col-3">
                <b>{{ "Jurisdiction" }}</b>
                <p class="mb-0">{{ juris_court[0] }}</p>
              </div>
  
              <div class="col-4">
                <b>Court/Institution</b>
                  <p v-for="(court, idx) in juris_court[1]" :key="idx" class="mb-0">{{ court }}</p>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="row">
        <div class="col-12 col-md-7">
          <div
            class="card border shadow-sm px-2 py-3 rounded mt-3 content-card"
          >
            <div class="row">
              <div class="col">
                <h4 :class="selectedTab === 'list' ? 'text-center' : 'mx-2'"><b>{{ "Case Flow Results" }}</b></h4>
              </div>
              <div class="col" v-if="selectedTab === 'view'">
                <button
                  style="margin-top: -.35rem;"
                  class="btn float-right text-muted"
                  @click="selectedTab = 'list'"
                >
                  &#8592; Back to Document List
                </button>
              </div>
            </div>

            <template v-if="selectedTab === 'list'">
              <!-- Toggle between pretrial / trial / post-trial results -->
             <div
                v-if="
                  (trial_data.length !== 0 || trial_duration !== 0) ||
                  (trialdoc_data.length !== 0 || trialdoc_campaign_loader) ||
                  final_submission
                "
                class="toggle__resultPhase my-2"
              >
                <!-- Pre-Trial -->
                <input
                  v-if="pretrial_data.length !== 0"
                  v-model="category"
                  id="preTrial"
                  type="radio"
                  name="phase"
                  value="preTrial"
                />
                <label
                  v-if="pretrial_data.length !== 0"
                  for="preTrial"
                  :class="[
                    'toggle__resultPhase-preTrial flex-column',
                    {'tabbutton-middle': final_submission}
                  ]"
                >
                  <span class="m-auto">
                    Pre-Trial
                  </span>
                </label>

                <!-- Trial -->
                <input
                  v-if="trial_data.length !== 0 || trial_duration !== 0"
                  v-model="category"
                  id="Trial"
                  type="radio"
                  name="phase"
                  value="Trial"
                />
                <label
                  v-if="trial_data.length !== 0 || trial_duration !== 0"
                  for="Trial"
                  :class="[
                    'toggle__resultPhase-Trial flex-column',
                    {'tabbutton-middle': trialdoc_data.length !== 0 || trialdoc_campaign_loader || final_submission}
                  ]"
                >
                  <span class="m-auto">
                    Trial
                  </span>
                </label>

                <!-- Trial Documents -->
                <input
                  v-if="trialdoc_data.length !== 0 || trialdoc_campaign_loader"
                  v-model="category"
                  id="trialDoc"
                  type="radio"
                  name="phase"
                  value="trialDoc"
                />
                <label
                  v-if="trialdoc_data.length !== 0 || trialdoc_campaign_loader"
                  for="trialDoc"
                  :class="[
                    'toggle__resultPhase-TrialDoc flex-column',
                    {'tabbutton-middle': final_submission}
                  ]"
                >
                  <span class="m-auto">
                    Trial Documents
                  </span>
                </label>

                <!-- Post-Trial -->
                <input
                  v-if="final_submission"
                  v-model="category"
                  id="postTrial"
                  type="radio"
                  name="phase"
                  value="postTrial"
                />
                <label
                  v-if="final_submission"
                  for="postTrial"
                  :class="[
                    'flex-column', 
                    pretrial_data.length ? 'toggle__resultPhase-postTrial' : 'toggle__resultPhase-postTrialRes'
                  ]"
                >
                  <span class="m-auto">Post-Trial</span>
                </label>
              </div>


              <!-- Pretrial data !== 0 -->
              <div v-if="category === 'preTrial' && pretrial_data.length">
                <div class="row mt-4">
                  <div class="col">
                    <h5 class="font-weight-bold mb-3 mt-2">Pre-Trial Phase</h5>
                  </div>
                  <div v-if="trial_data.length !== 0" class="col">
                    <button
                      class="btn btn-primary float-right"
                      @click="DownloadBulk('preTrial')"
                    >
                      {{ "Bulk Download" }}
                    </button>
                  </div>
                </div>

                <table class="table border shadow-sm p-2 rounded table-card mt-2">
                  <thead>
                    <tr>
                      <th scope="col" width="60%">
                        {{ "Document" }}
                      </th>
                      <th
                        scope="col"
                        width="30%"
                        class="text-center"
                        style="padding-right: 20px"
                        v-if="permissions.includes('nexlaw.les')"
                      >
                        {{ "Action" }}
                      </th>
                    </tr>
                  </thead>

                  <tbody
                    :style="{
                      height: `${
                        (trial_data.length ||
                        (trial_duration.length == 0 || !trial_campaign_loader)) || 
                        final_submission || !showEndCaseButton 
                          ? 410 : 357
                      }px`
                    }"
                  >
                    <PageLoader
                      v-if="pageLoading"
                      :loading="pageLoading"
                    ></PageLoader>

                    <div
                      v-else-if="pretrial_data.length === 0"
                      class="container_margin"
                    >
                      <div class="mb-2 m-auto" style="width: 200px">
                        <img
                          src="@/assets/noData.png"
                          alt="No Data Image"
                        />
                      </div>
                      <p class="text-center text-blue font-weight-bold">
                        {{ "No Data, Kindly generate new case flow" }}
                      </p>
                    </div>

                    <tr
                      v-else
                      v-for="(item, index) in pretrial_data"
                      :key="index"
                    >
                      <td width="60%">
                        <p class="mb-0 font-weight-bold">
                          {{ getKey(item, "Les-") | capitalize }}
                        </p>
                        <small> ({{ explanations[index] }}) </small>
                      </td>
                      <td
                        width="30%"
                        class="text-center"
                        v-if="permissions.includes('nexlaw.les')"
                      >
                        <!-- to editor -->
                        <!-- <button
                          class="btn btn-primary mx-2 w-75"
                          @click="openCase(index)"
                        >
                          Open
                        </button> -->

                        <button
                          class="btn btn-primary mx-2"
                          @click="viewData(index, 'preTrial')"
                        >
                          {{ "View" }}
                        </button>
                        <button class="btn btn-primary-outlined mx-2">
                          <span
                            class="material-symbols-outlined"
                            @click="DownloadWords(index, 0)"
                          >
                            sim_card_download
                          </span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div class="text-end">
                  <button
                    v-if="trial_data.length == 0 &&
                      (trial_duration.length == 0 || !trial_campaign_loader) && 
                      !final_submission"
                    class="btn mt-3 btn-primary mx-2"
                    @click="openTrialInput"
                  >
                    {{ "Move to Trial Phase" }}
                  </button>
                    <button
                    v-if="showEndCaseButton && !trial_campaign_loader && !final_submission && !trialdoc_data.length && !trial_data.length"
                    class="btn mt-3 btn-primary"
                    @click="endCase"
                  >
                    {{ "End Case" }}
                  </button>
                </div>
              </div>

              <!-- Trial data !== 0 -->
              <div v-if="category === 'Trial'">
                <div class="row mt-4">
                  <div class="col-6">
                    <h5 class="mb-3 mt-2"><b>Trial Phase</b></h5>
                  </div>
                  <div v-if="trial_data.length !== 0" class="col-6">
                    <button
                      class="btn btn-primary float-right"
                      @click="DownloadBulk('Trial')"
                    >
                      {{ "Bulk Download" }}
                    </button>
                  </div>
                </div>

                <table class="table card border shadow-sm rounded table-card mt-2">
                  <thead>
                    <tr>
                      <th scope="col" width="60%">
                        {{ "Document" }}
                      </th>
                      <th
                        scope="col"
                        width="30%"
                        class="text-center"
                        style="padding-right: 20px"
                        v-if="permissions.includes('nexlaw.les_trial')"
                      >
                        {{ "Action" }}
                      </th>
                    </tr>
                  </thead>

                  <tbody :style="{
                    height: `${trial_campaign_loader || final_submission || !showEndCaseButton || trialdoc_data.length ? 410 : 357}px`
                  }">
                    <PageLoader
                      v-if="trial_campaign_loader"
                      :duration="trial_duration"
                      :message="[
                        'Generating Trial Case Flow',
                        'Please wait a moment while we process it. This may take 3 to 4 minutes.',
                      ]"
                    ></PageLoader>

                    <div
                      v-else-if="!trial_campaign_loader && trial_data.length === 0"
                      class="container_margin"
                    >
                      <div class="mb-2 m-auto" style="width: 200px">
                        <img
                          src="@/assets/noData.png"
                          alt="No Data Image"
                        />
                      </div>
                      <p class="text-center text-blue font-weight-bold">
                        {{ "No Data, Kindly generate new case flow" }}
                      </p>
                    </div>

                    <tr v-else v-for="(item, index) in trial_data" :key="index">
                      <td width="60%">
                        <p class="mb-0 font-weight-bold">
                          {{ getKey(item, "LesTrial-") | capitalize }}
                        </p>
                        <small>({{ trial_explanations[index] }})</small>
                      </td>
                      <td
                        width="30%"
                        class="text-center"
                        v-if="permissions.includes('nexlaw.les_trial')"
                      >
                        <!-- to editor -->
                        <!-- <button
                          class="btn btn-primary mx-2"
                          @click="openCase(index)"
                        >
                          Open
                        </button> -->

                        <button
                          class="btn btn-primary mx-2"
                          @click="viewData(index, 'Trial')"
                        >
                          {{ "View" }}
                        </button>
                        <button
                          class="btn btn-primary-outlined mx-2"
                          @click="DownloadWords(index, 1)"
                        >
                          <span class="material-symbols-outlined">
                            sim_card_download
                          </span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                
                <div class="text-end" v-if="!final_submission || !trial_campaign_loader">
                  <button
                    v-if="showTrialButton && !trial_campaign_loader && !final_submission && !trialdoc_data.length"
                    class="btn mt-3 btn-primary mx-2"
                    @click="newTrial"
                  >
                    {{ "Upload Trial Documents" }}
                  </button>
                  <button
                    v-if="showEndCaseButton && !trial_campaign_loader && !final_submission && !trialdoc_data.length"
                    class="btn mt-3 btn-primary"
                    @click="endCase"
                  >
                    {{ "End Case" }}
                  </button>
                </div>
              </div>
              
              <!-- Trial Documents !== 0 -->
              <div v-if="category === 'trialDoc'">
                <div class="row mt-4">
                  <div class="col-6">
                    <h5 class="mb-3 mt-2"><b>Trial Documents Phase</b></h5>
                  </div>
                  <div v-if="trialdoc_data.length !== 0" class="col-6">
                    <button
                      class="btn btn-primary float-right"
                      @click="DownloadBulk('trialDoc')"
                    >
                      {{ "Bulk Download" }}
                    </button>
                  </div>
                </div>

                <table class="table card border shadow-sm rounded table-card mt-2">
                  <thead>
                    <tr>
                      <th scope="col" width="60%">
                        {{ "Document" }}
                      </th>
                      <th
                        scope="col"
                        width="30%"
                        class="text-center"
                        style="padding-right: 20px"
                        v-if="permissions.includes('nexlaw.les_trial')"
                      >
                        {{ "Action" }}
                      </th>
                    </tr>
                  </thead>

                  <tbody :style="{
                    height: `${trialdoc_campaign_loader || final_submission || !showEndCaseButton ? 410 : 357}px`
                  }">
                    <PageLoader
                        v-if="trialdoc_campaign_loader"
                        :duration="trial_doc"
                        :message="[
                          'Generating Trial Documents',
                          'Please wait a moment while we process it. This may take a few minutes.',
                        ]"
                      ></PageLoader>

                    <div
                      v-else-if="!trialdoc_campaign_loader && trialdoc_data.length === 0"
                      class="container_margin"
                    >
                      <div class="mb-2 m-auto" style="width: 200px">
                        <img
                          src="@/assets/noData.png"
                          alt="No Data Image"
                        />
                      </div>
                      <p class="text-center text-blue font-weight-bold">
                        {{ "No Data, Kindly generate new case flow" }}
                      </p>
                    </div>

                    <tr v-else v-for="(item, index) in trialdoc_data" :key="index">
                      <td width="60%">
                        <p class="mb-0 font-weight-bold">
                          {{ getKey(item, "_") | capitalize }}
                        </p>
                        <small>({{ trialdoc_explanations[index] }})</small>
                      </td>
                      <td
                        width="30%"
                        class="text-center"
                        v-if="permissions.includes('nexlaw.les_trial')"
                      >

                        <button
                          class="btn btn-primary mx-2"
                          @click="viewData(index, 'trialDoc')"
                        >
                          {{ "View" }}
                        </button>
                        <button
                          class="btn btn-primary-outlined mx-2"
                          @click="DownloadWords(index, 3)"
                        >
                          <span class="material-symbols-outlined">
                            sim_card_download
                          </span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                
                <div class="text-end" v-if="!final_submission || !trialdoc_campaign_loader">
                  <button
                    v-if="showTrialButton && !trialdoc_campaign_loader && !final_submission"
                    class="btn mt-3 btn-primary mx-2"
                    @click="newTrial"
                  >
                    {{ "Upload Trial Documents" }}
                  </button>
                  <button
                    v-if="showEndCaseButton && !trialdoc_campaign_loader && !final_submission"
                    class="btn mt-3 btn-primary"
                    @click="endCase"
                  >
                    {{ "End Case" }}
                  </button>
                </div>
              </div>

              <!-- PostTrial -->
              <div v-if="category === 'postTrial'">
                <div class="row mt-4">
                  <div class="col-6">
                    <h5 class="mb-3 mt-2"><b>Post-Trial Phase</b></h5>
                  </div>
                  <div class="col-6">
                    <button
                      class="btn btn-primary float-right"
                      @click="DownloadBulk('postTrial')"
                    >
                      {{ "Bulk Download" }}
                    </button>
                  </div>
                </div>

                <table class="table card border shadow-sm rounded table-card mt-2">
                  <thead>
                    <tr>
                      <th scope="col" width="60%">
                        {{ "Document" }}
                      </th>
                      <th
                        scope="col"
                        width="30%"
                        class="text-center"
                        style="padding-right: 20px"
                      >
                        {{ "Action" }}
                      </th>
                    </tr>
                  </thead>

                  <tbody style="height: 410px">
                  <PageLoader
                      v-if="posttrial_campaign_loader"
                      :duration="trial_doc"
                      :message="[
                        'Generating  Post Trial Case Flow',
                        'Please wait a moment while we process it. This may take 3 to 4 minutes.',
                      ]"
                    ></PageLoader>
                    <div
                      v-else-if="posttrial_data.length === 0"
                      class="container_margin"
                    >
                      <div class="mb-2 m-auto" style="width: 200px">
                        <img
                          src="@/assets/noData.png"
                          alt="No Data Image"
                        />
                      </div>
                      <p class="text-center text-blue font-weight-bold">
                        {{ "No Data, Kindly generate new case flow" }}
                      </p>
                    </div>

                    <tr v-else v-for="(item, index) in posttrial_data" :key="index">
                      <td width="60%">
                        <p class="mb-0 font-weight-bold">
                          {{ getKey(item, "Les-") | capitalize }}
                        </p>
                        <small>({{ posttrial_explanations[index] }})</small>
                      </td>
                      <td
                        width="30%"
                        class="text-center"
                        v-if="permissions.includes('nexlaw.les_trial')"
                      >
                        <!-- to editor -->
                        <!-- <button
                          class="btn btn-primary mx-2"
                          @click="openCase(index)"
                        >
                          Open
                        </button> -->

                        <button
                          class="btn btn-primary mx-2"
                          @click="viewData(index, 'postTrial')"
                        >
                          {{ "View" }}
                        </button>
                        <button
                          class="btn btn-primary-outlined mx-2"
                          @click="DownloadWords(index, 2)"
                        >
                          <span class="material-symbols-outlined">
                            sim_card_download
                          </span>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>

            <template v-else> 
              <div class="card mt-1 popUp_card p-4">
                <span v-html="view_data"></span>
              </div>
            </template>
          </div>
        </div>
        <div class="col-12 col-md-5">
          <div
            class="card border shadow-sm px-2 py-2 rounded mt-3 content-card"
          >
           <div class="underlined d-flex justify-content-between align-items-center">
              <h4 class="px-2 pb-1 mb-0"><b>LawBot</b></h4>
              <!-- <a @click="expertMode" class="px-2 pb-1 mb-0" style="cursor:pointer;"><b>Expert Mode</b></a> -->
            </div>

            <div class="card border-0 card_question">
              <div class="chat__box" id="messages" ref="messages1">
                <!-- Incoming Chat box -->
                <div
                  v-for="(message, idx) in chatMessages"
                  :key="idx"
                  :class="message.class_1"
                >
                  <div :class="message.class_1">
                    <div :class="message.class_3">
                      <span v-html="
                        message.text
                        .replace(/\\n/g, '\n') // Convert literal '\n' to actual newline
                        .replace(/^\s*#+\s*(.*?)\s*$/gm, '<br><b>$1</b><br>') // Make lines starting with ### or #### bold
                        .replace(/\n/g, '<br>') // Replace actual newline characters with <br>
                        .replace(/\n+/g, '<br>') // Replace multiple actual newlines with a single <br>
                        .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>') // Convert text between ** to <b>
                        .replace(/\*/g, '') // Remove any remaining asterisks (single)
                        .replace(/\\/g, '<br>') // Replace backslashes with <br>
                      "></span>
                    </div>
                  </div>
                </div>
                <!-- Outgoing chat box -->
                <div v-if="loadAnswer" class="chat__incoming">
                  <div class="chat__bubble__wrapper-incoming">
                    <div
                      class="chat__bubble-incoming animated-background-incoming"
                    >
                      <div
                        style="transform: scale(0.7)"
                        class="circles-to-rhombuses-spinner"
                      >
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="chat__suggestion"
                v-for="(question, index) in default_questions.slice(0, 3)"
                :key="index"
                @click="chooseMessage(question, index)"
                :disabled="loadAnswer || question.displayed"
              >
                <span>{{ question.text }}</span>
              </button>

              <div class="chat__input">
                <input
                  :disabled="loadAnswer"
                  @keyup.enter="sendNewMessage"
                  type="text"
                  v-model="NewText"
                  placeholder="Ask Questions about the Case..."
                />
                <button
                  v-show="NewText"
                  @click="sendNewMessage"
                  :disabled="loadAnswer"
                  class="btn-send"
                >
                  <span class="material-icons-sharp">arrow_circle_right</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageLoader from "../../components/PageLoader.vue";
import LES from "@/store/LES.js";
import Auth from "@/store/Auth.js";
import JSZip from "jszip";
import { courts } from "../CaseSearch/courtnames";
import { ExportDataDoc } from "../../store/utils";

export default {
  components: {
    PageLoader,
  },

  data() {
    return {
      pageLoaderMessage:"",
      trial_doc: 50,
      isCreatingTrial: false,
      caseEnded: false,
      ongoingTrials: [],
      postLoading:false,
      permissions: localStorage.permissions,
      pageLoading: true,
      loadingPostTrial: false,
      pretrial_data: [],
      trial_data: [],
      trialdoc_data: [],
      posttrial_data: [],
      explanations: [],
      posttrial_explanations: [],
      trialdoc_explanations: [],
      timeout_timer: [],
      timer_count: [],
      duration: -1,
      interval: null,
      route_id: this.$route.params.id,
      view_data: { output: "" },
      // view_header: "",
      trial_campaign_loader: false,
      trialdoc_campaign_loader: false,
      posttrial_campaign_loader: false,
      PostTrialCompletedPerCentage:0,
      trial_duration: 0,
      trial_timer: null,
      category: "",
      complete: null,
      client_case: "",
      client_type: "",
      jurisdiction: "",
      pre_event: "",
      pre_event_file: "",
      witness_client: "",
      witness_opposing: "",
      courtname: "",
      lawyer: "",
      judge: "",
      selectedTab: "list",
      final_submission: null,
      mode:"",

      // chatbot
      default_questions: [
        {
          text: "List out all the legislations in the documents.",
          displayed: false,
        },
        {
          text: "Give an elaborated explanation for the legislations.",
          displayed: false,
        },
        {
          text: "Among the witnesses presented who possesses a greater weakness to the case and why?",
          displayed: false,
        },
        {
          text: "Among the witnesses presented who possesses a greater strength to the case and why?",
          displayed: false,
        },
        {
          text: "Give more explanation regarding the weakest witness.",
          displayed: false,
        },
        {
          text: "Give more explanation regarding the strongest witness.",
          displayed: false,
        },
      ],
      NewText: "",
      loadAnswer: false,
      chatMessages: [],
    };
  },

  methods: {
    viewData(index, type) {
      this.selectedTab = "view";
      let data;
        switch (type) {
          case "preTrial":
            data = this.pretrial_data;
            break;
          case "postTrial":
            data = this.posttrial_data;
            break;
          case "trialDoc":
            data = this.trialdoc_data;
            break;
          default:
            data = this.trial_data;
        }

      for (const [key, val] of Object.entries(data[index])) {
        this.view_data = JSON.parse(val).output;
        if (type == "preTrial") {
          this.view_data = this.view_data.replaceAll(/\n\s*- /g, "\n");
        }

        // Perform various text replacements
        this.view_data = this.view_data
          .replaceAll(/\n\s*(\d+)\s/g, "\n$1. ")          // Add period after digits with space
          .replaceAll(/\n(\d+)(?![\d\.)])/g, "\n$1. ")   // Add period after digits without space
          .replaceAll("\n", "<br>")                      // Convert newlines to <br>
          .replaceAll(/\*\*(.*?)\*\*/g, "<b>$1</b>")     // Convert **text** to bold
          .replaceAll(/\*(.*?)\*/g, "<b>$1</b>")         // Convert *text* to bold
          .replaceAll(/<a href="/g, '<a target="_blank" href="') // Open links in a new tab
          .replaceAll(/^# (.*?)$/gm, "<b>$1</b>")
          .replaceAll(/<br>## (.*?)(<br>|$)/g, "<br><b style='font-size:20px;'>$1</b><br>")  
          .replaceAll(/<br>### (.*?)(<br>|$)/g, "<br><b style='font-size:20px;'>$1</b><br>");  

        this.view_data = this.view_data.replaceAll("#", ""); 
        
        const IMGStyle = `width: auto; height: 400px; border: 1px solid gray;`;
            if (JSON.parse(val).cite) {
              this.view_data = this.view_data +
                "<b>Evidences Presented:</b><br><br>" +
                Object.entries(JSON.parse(val).cite).map((data, id) => {
                  // console.log(data);
                  return `${id+1}. <b>${data[0]}</b><br><img style="${IMGStyle}" src="data:image/png;base64,${data[1]}" alt="${data[0]}" />`;
                }).join("<br><br>")
            }

        // Format the header
        // this.view_header = key
        //   .replace(type == "preTrial" ? "Les-" : "LesTrial-", "")
        //   .replace(/_/g, " ");

        // Show the modal
        // this.$bvModal.show("show-data-legal-expert");
      }
    },

    openTrialInput() {
      localStorage.setItem(
        "isWitnessClientEmpty",
        Array.isArray(this.witness_client) ? this.witness_client.length === 0 : Object.keys(this.witness_client).length === 0
      );
      localStorage.setItem(
        "isWitnessOpposingEmpty",
        Array.isArray(this.witness_opposing) ? this.witness_opposing.length === 0 : Object.keys(this.witness_opposing).length === 0
      );

      this.$router.push({
        name: "LegalExpertSystemFileInput",
        params: {
          id: this.$route.params.id,
        },
      });
    },

    endCase() {
      if (!this.showEndCaseButton) {
        return false;
      }
      this.caseEnded = true;
      this.$router.push({
        name: "LegalExpertSystemEndCase",
        params: {
          id: this.$route.params.id,
        },
      });
    },

     newTrial() {
      if (!this.showTrialButton) {
        return false;
      }
      // this.trialdoc_campaign_loader = true;
      this.isCreatingTrial = true;
      localStorage.setItem('isCreatingTrial', 'true');
      this.$router.push({
        name: "LegalExpertSystemNewTrial",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    expertMode(){
      this.$router.push({
        name: "LegalExpertMode",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    openPDF() {
      window.open(this.pre_event_file, "_blank");
    },

    openCase(data) {
      // console.log(this.pretrial_data);
      // console.log(this.trial_data);
      this.$router.push({
        name: "PreTrialDocView",
        params: {
          id: this.$route.params.id,
          file: data,
          category: this.category,
        },
      });
    },

    async DownloadBulk(type) {
      const ZIP = new JSZip();
      const DATA = type === "preTrial"
        ? this.pretrial_data
        : type === "trialDoc"
        ? this.trialdoc_data
        : type === "postTrial"
        ? this.posttrial_data
        : this.trial_data;
      for (const [k, raw] of Object.entries(DATA)) {
        for (const [key, val] of Object.entries(raw)) {
          var output = JSON.parse(val);

          var header =
            "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
            "xmlns:w='urn:schemas-microsoft-com:office:word' " +
            "xmlns='http://www.w3.org/TR/REC-html40'>" +
            `<head><meta charset='utf-8'><title>${key}</title></head><meta name="description" content="${output.explaination}"><body>`;
          var footer = "</body></html>";

          var sourceHTML =
            header + output.output.replaceAll("\n", `<br>`) + footer;
          // sourceHTML =
          //   "data:application/vnd.ms-word;charset=utf-8," +
          //   encodeURIComponent(sourceHTML);
          var blob = new Blob([sourceHTML], { type: "application/msword" });

          // Add the file to the zip folder
          ZIP.file(`${key.replaceAll("-", "_")}.doc`, blob);
        }
      }

      // // Generate the zip file asynchronously
      // zip.generateAsync({ type: "blob" }).then(function (content) {
      //   // Save the zip file using FileSaver.js
      //   saveAs(content, "documents.zip");
      // });

      // Generate the zip file as a Blob
      const blob2 = await ZIP.generateAsync({ type: "blob" });
      // Create a link to download the Blob
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob2);
      link.download = "documents.zip";

      // Click the link to start the download
      link.click();
      link.remove();
    },

    DownloadWords(data, category) {
      let DATA;
        switch (category) {
          case 0:
            DATA = this.pretrial_data;
            break;
          case 1:
            DATA = this.trial_data;
            break;
          case 2:
            DATA = this.posttrial_data;
            break;
          case 3:
            DATA = this.trialdoc_data;
            break;
          default:
            this.$toast.error("Invalid category");
            return;
        }

      for (const [key, value] of Object.entries(DATA[data])) {
        // Apply transformations to the data
        let transformedData = JSON.parse(value).output
            .replaceAll(/\n\s*(\d+)\s/g, "\n$1. ")    
            .replaceAll(/\n(\d+)(?![\d\.)])/g, "\n$1. ") 
            .replaceAll("\n", "<br>")                   
            .replaceAll(/\*\*(.*?)\*\*/g, "<b>$1</b>")   
            .replaceAll(/\*(.*?)\*/g, "<b>$1</b>")       
            .replaceAll(/<a href="/g, '<a target="_blank" href="') 
            .replaceAll(/^# (.*?)$/gm, "<b>$1</b>")    
            .replaceAll(/<br>## (.*?)(<br>|$)/g, "<br><b style='font-size:20px;'>$1</b><br>") 
            .replaceAll(/<br>### (.*?)(<br>|$)/g, "<br><b style='font-size:16px;'>$1</b><br>") 
            .replaceAll("#", "");                         

        ExportDataDoc(transformedData);
      }

      this.$toast.success("Successfully downloaded Document");
    },

    // DownloadPDF(data) {
    //   // console.log(data);
    //   // console.log(this.pretrial_data[data]);
    //   for (const [key, value] of Object.entries(this.pretrial_data[data])) {
    //     // console.log(key, value);
    //     var output = JSON.parse(value);
    //     // console.log(output.output);

    //     // var html = htmlToPdfmake(sourceHTML);

    //     const documentDefinition = { content: output.output };
    //     pdfMake.vfs = pdfFonts.pdfMake.vfs;
    //     pdfMake.createPdf(documentDefinition).open();
    //   }
    // },

    // DownloadTrialPDF(data) {
    //   for (const [key, value] of Object.entries(this.trial_data[data])) {
    //     var output = JSON.parse(value);
    //     // console.log(output.output);

    //     // var html = htmlToPdfmake(sourceHTML);

    //     const documentDefinition = { content: output.output };
    //     pdfMake.vfs = pdfFonts.pdfMake.vfs;
    //     pdfMake.createPdf(documentDefinition).open();
    //   }
    // },

    getKey(obj, prefix) {
      return Object.keys(obj)[0].replace(prefix, "").replace(/_/g, " ");
    },

    getCourt(code) {
      if (["USA", "United States"].includes(this.jurisdiction)) {
        this.jurisdiction = "United States of America";
      } else {
        this.jurisdiction = this.jurisdiction;
      }
      const cat_courts = courts[this.jurisdiction].courts;
      for (var i = 0; i < cat_courts.length; i++) {
        for (const cat in cat_courts[i].courts) {
          var crt = cat_courts[i].courts[cat];
          if (crt.id == code) {
            return crt.name;
          }
        }
      }
    },

    getResearchCourt(jurisdiction, code) {
      let jurisdictionName;

      if (jurisdiction === "USA") {
        jurisdictionName = "United States of America";
      } else {
        jurisdictionName = jurisdiction;
      }

      if (!courts[jurisdictionName]) {
        return null;
      }

      const cat_courts = courts[jurisdictionName].courts;
      for (let category of cat_courts) {
        for (let court of category.courts) {
          if (court.id == code) {
            return court.name;
          }
        }
      }

      console.warn(`Court with code "${code}" not found in "${jurisdictionName}".`);
      return null;
    },

    chooseMessage(message, index) {
      this.loadAnswer = true;
      this.chatMessages.push({
        text: message.text,
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      });

      // Update the displayed property of the chosen question
      this.default_questions[index].displayed = true;
      // // Find the next question that hasn't been displayed
      // const nextQuestion = this.default_questions
      //   .find((question) => !question.displayed);

      var obj = {
        question: message.text,
        mask: "false",
      };
      console.log("Passed into API", obj);

      LES.sendQuestion("les", this.$route.params.id, obj)
        .then(() => {
          this.default_questions.splice(
            this.default_questions.findIndex((x) => x.text === message.text),
            1
          );
          this.calltime("default");
        })
        .catch((error) => {
          console.log(error);
          this.loadAnswer = false;
        });

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    sendNewMessage() {
      if (this.NewText.length != 0) {
        this.loadAnswer = true;
        this.chatMessages.push({
          text: this.NewText,
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        });

        var obj = {
          question: this.NewText,
          mask: "false",
        };
        this.NewText = "";

        console.log("Passed into API", obj);

        LES.sendQuestion("les", this.$route.params.id, obj)
          .then(() => {
            Auth.getMe().then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              const credits = res.data.data.nexlaw_credits;
              this.contract_questions_full = credits.full.contract_questions;
              this.contract_questions_remain =
                credits.full.contract_questions -
                credits.used.contract_questions;
            });
            this.calltime("none");
          })
          .catch((error) => {
            this.loadAnswer = false;
            console.log(error);
          });

        var div = document.getElementById("messages");
        div.scrollTop = div.scrollHeight;

        this.$nextTick(() => {
          var messageDisplay = this.$refs.messages1;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        });
      }
    },

    calltime(data) {
      var me = this;
      setTimeout(function () {
        if (!me.$route.params.id) return;

        LES.GetLES(me.$route.params.id)
          .then((response) => {
            const questions = response.data.data.questions;
            const last_q = questions[questions.length - 1];
            if (last_q.answer != null) {
              try {
                JSON.parse(last_q.answer).forEach((answer) => {
                  me.chatMessages.push({
                    text: answer,
                    class_1: "chat__incoming",
                    class_2: "chat__bubble__wrapper-incoming",
                    class_3:
                      "chat__bubble-incoming animated-background-incoming",
                    new: true,
                  });
                });
              } catch (error) {
                me.chatMessages.push({
                  text: last_q.answer,
                  class_1: "chat__incoming",
                  class_2: "chat__bubble__wrapper-incoming",
                  class_3: "chat__bubble-incoming animated-background-incoming",
                  new: true,
                });
              }
              me.loadAnswer = false;

              if (data == "default") {
                me.default_questions.splice(index, 1);
              }
            } else {
              me.calltime(data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5000);
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.messages1;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
  },
 computed: {
    showTrialButton() {
      return !this.caseEnded && this.ongoingTrials.length < 3;
    },
    showEndCaseButton() {
      return !this.caseEnded;
    },
  },

  created() {
    this.pageLoading = true;
    LES.GetLES(this.$route.params.id)
      .then((response) => {
        this.category = this.$route.params.category;
        if (this.category === "preTrial") {
          this.pageLoaderMessage = [
            'Generating Pre-Trial Case Flow',
            'Please wait a moment while we process it. This may take 3 to 4 minutes.',
          ];
        } else if (this.category === "postTrial") {
          this.pageLoaderMessage = [
            'Generating Post-Trial Case Flow',
            'Please wait a moment while we process it. This may take 3 to 4 minutes.',
          ];
        }
        const DATA = response.data.data;
        this.pageLoading = false;

        this.final_submission = DATA?.final_submission_trial_doc ?? null;

        if(DATA.type === "les_submission"){
        if (this.final_submission && DATA.lesValues.length > 0) {
          DATA.lesValues.forEach((element) => {
            this.posttrial_data.push(element);
          });
        }
        this.posttrial_explanations = this.posttrial_data.map((item) => {
            const key = Object.keys(item)[0];
            const value = JSON.parse(item[key]).explaination;
            return value;
          });
        }

        this.duration = 0;
        if (DATA.completedPerCentage < 100) {
          if (DATA.completedPerCentage > 0) {
            this.duration = DATA.completedPerCentage;
          }

          this.interval = setInterval(() => {
            LES.GetLES(this.$route.params.id).then((res) => {
              if (res.data.data.completedPerCentage >= 100) {
                this.duration = res.data.data.completedPerCentage;
                clearInterval(this.interval);
                location.reload();
              } else if (res.data.data.completedPerCentage > 0) {
                this.duration = res.data.data.completedPerCentage;
              }
            });
          }, 10000);
        } else {
          this.duration = 100;
          this.category = this.$route.params.category;
          this.complete = this.$route.params.complete;
          this.client_case = DATA.client_case;

          this.client_type = DATA.client_type;
          this.jurisdiction = DATA.jurisdiction;
          if (this.jurisdiction.includes('"')) {
            var raw = this.jurisdiction.split('"');
            this.jurisdiction = ["USA", "United States"].includes(raw[1])
              ? "United States of America"
              : raw[1];
            this.courtname = raw[3] == "all" ? "all" : this.getCourt(raw[3]);
          }

          this.research_jurisdictions_courts = Object.entries(JSON.parse(DATA.jurisdiction)).slice(1).map(([key, value]) => {
            value = value.map((code) => {
              if (code !== "all") {
                return this.getResearchCourt(key, code);
              } else {
                return "All";
              }
            });
            return [key, value];
          });

          if (DATA.lawyer) {
            if (typeof DATA.lawyer === 'object') {
              this.lawyer = `${DATA.lawyer.first_name} ${DATA.lawyer.last_name}`;
            } else if (typeof DATA.lawyer === 'string') {
              this.lawyer = DATA.lawyer;
            }
          }
          if (DATA.judge) {
            if (typeof DATA.judge === 'object') {
              this.judge = `${DATA.judge.first_name} ${DATA.judge.last_name}`;
            } else if (typeof DATA.judge === 'string') {
              this.judge = DATA.judge;
            }
          }

          this.pre_event = DATA.pre_event;
          this.pre_event_file = DATA.pre_event_file;

          this.witness_client = DATA.witness_client;
          this.witness_opposing = DATA.witness_opposing;

          // DATA.lesValues.forEach((element) => {
          //   this.pretrial_data.push(element);
          // });
          this.pretrial_data = DATA.lesValues.filter((item) => {
            return !Object.keys(item).some(key => key.startsWith("Les-ongoing_trial"));
        });

          // if (this.jurisdiction !== "USA") {
          //   this.pretrial_data = this.pretrial_data.filter((item) => {
          //     return !(
          //       item.hasOwnProperty("Les-discovery") ||
          //       item.hasOwnProperty("Les-pretrial_motion")
          //     );
          //   });
          // }

          // if (this.jurisdiction === "United Kingdom") {
          //   this.pretrial_data = this.pretrial_data.filter((item) => {
          //     return !item.hasOwnProperty("Les-pleading");
          //   });
          // }

          DATA.lesTrialValues.forEach((element) => {
            this.trial_data.push(element);
          });
          
          // this.ongoingTrials = DATA.ongoingTrials || [];
          // this.ongoingTrials.forEach((element) => {
          //   element.results.forEach((result) => {
          //     this.trial_data.push(result);
          //   });
          // });

          // Initial setup to process ongoing trials and start the loader
         this.ongoingTrials = DATA.ongoingTrials || [];
         
        //  move ongoing to trial documents from pretrial
          const checkOngoingTrials = () => {
            const ongoingTrialItems = [];
            this.pretrial_data.forEach((item, index) => {
              const hasOngoingTrialKey = Object.keys(item).some(key => key.startsWith("Les-ongoing_trial"));
              if (hasOngoingTrialKey) {
                ongoingTrialItems.push(index);
              }
            });
            if (ongoingTrialItems.length > 0) {
              this.trialdoc_data.push(...ongoingTrialItems.map(index => {
                const newItem = {};
                Object.keys(this.pretrial_data[index]).forEach(key => {
                  if (key.startsWith("Les-ongoing_trial")) {
                    const newKey = this.getKey({ [key]: '' }, "Les-");
                    newItem[newKey] = this.pretrial_data[index][key];
                  }
                });

                return newItem;
              }));
              this.pretrial_data = this.pretrial_data.filter((_, index) => !ongoingTrialItems.includes(index));
              this.trialdoc_explanations.push(...ongoingTrialItems.map(index => this.explanations[index]));
              this.explanations = this.explanations.filter((_, index) => !ongoingTrialItems.includes(index));
            }
          };
          const processOngoingTrials = () => {
            const newTrialdocData = [];
            let resultIndex = 1;

            this.ongoingTrials.forEach((element) => {
              element.results.forEach((result) => {
                const processedResult = {};
                for (const key in result) {
                  processedResult[`_${key}_${resultIndex}`] = result[key];
                }
                newTrialdocData.push(processedResult);
              });
              resultIndex++;
            });
            this.trialdoc_data = newTrialdocData;
          };
          const startPolling = () => {
            if (this.trialInterval) {
              clearInterval(this.trialInterval);
            }
            this.trialInterval = setInterval(() => {
              LES.GetLES(this.$route.params.id)
                .then((res) => {
                  const data = res.data.data;
                  this.ongoingTrials = data.ongoingTrials || [];
                  const previousLength = this.trialdoc_data.length;
                  processOngoingTrials();
                  const currentLength = this.trialdoc_data.length;

                  this.trialdoc_explanations = this.trialdoc_data.map((item) => {
                    const key = Object.keys(item)[0];
                    const value = JSON.parse(item[key]).explaination;
                    return value;
                  });

                  // Stop polling only if new data was added
                  if (currentLength > previousLength) {
                    this.trialdoc_campaign_loader = false;
                    clearInterval(this.trialInterval);
                  }
                })
                .catch((error) => {
                  console.error('Error fetching trial data:', error);
                  this.trialdoc_campaign_loader = false;
                  clearInterval(this.trialInterval);
                });
            }, 10000);
          };
          if (this.complete) {
            this.trialdoc_campaign_loader = true;
            startPolling();
          }
          if (this.ongoingTrials.length > 0) {
            processOngoingTrials();
          }

          this.trialdoc_explanations = this.trialdoc_data.map((item) => {
            const key = Object.keys(item)[0];
            const value = JSON.parse(item[key]).explaination;
            return value;
          });

          this.explanations = this.pretrial_data.map((item) => {
            const key = Object.keys(item)[0];
            const value = JSON.parse(item[key]).explaination;
            return value;
          });

          this.trial_explanations = this.trial_data.map((item) => {
            const key = Object.keys(item)[0];
            const value = JSON.parse(item[key]).explaination;
            return value === "Draft of an initial statement to the jury outlining the case"
              ? "Draft of an initial statement outlining the case"
              : value;
          });

          // this.posttrial_explanations = this.posttrial_data.map((item) => {
          //   const key = Object.keys(item)[0];
          //   const value = JSON.parse(item[key]).explaination;
          //   return value === "Draft of an initial statement to the jury outlining the case"
          //     ? "Draft of an initial statement outlining the case"
          //     : value;
          // });
          // response.data.data.witness_client.forEach((element) => {
          //   this.witness_client.push(element);
          // });

          // response.data.data.witness_opposing.forEach((element) => {
          //   this.witness_opposing.push(element);
          // });

          // console.log("this.client_case", this.client_case);
          // console.log("this.client_type", this.client_type);
          // console.log("this.jurisdiction", this.jurisdiction);
          // console.log("this.pre_event", this.pre_event);
          // console.log("this.pre_event_file", this.pre_event_file);
          // console.log("this.witness_client", this.witness_client.key);
          // console.log("this.witness_opposing", this.witness_opposing);

          // console.log("LesValues");
          // console.log(this.pretrial_data);

          // console.log("LesValues New");
          // console.log(JSON.parse(JSON.stringify(this.pretrial_data)));

          // console.log("lesTrialValues");
          // console.log(this.trial_data);

          // console.log("explanations");
          // console.log(this.explanations);

          // console.log("trial_explanations");
          // console.log(this.trial_explanations);
        }
        const checkFinalSubmission = () => {
            const ID = this.pretrial_data.findIndex(x => {
              const KEY = Object.keys(x);
              return KEY.includes("Les-final_brief") || KEY.includes("Les-final_submission");
            });

            if (ID !== -1) {
              this.posttrial_campaign_loader = false;
              this.posttrial_data.push(...this.pretrial_data.splice(ID, 1));
              this.posttrial_explanations.push(...this.explanations.splice(ID, 1));
              clearInterval(this.posttrialInterval);
            } else {
              this.posttrial_campaign_loader = true;
            }
          };
          if (this.final_submission) {
            checkFinalSubmission();

            if (this.posttrial_campaign_loader) {
              this.posttrialInterval = setInterval(() => {
                LES.GetLES(this.$route.params.id).then((res) => {
                  const data = res.data.data;
                  this.pretrial_data = data.lesValues || [];
                  this.explanations = this.pretrial_data.map((item) => {
                    const key = Object.keys(item)[0];
                    const value = JSON.parse(item[key]).explaination;
                    return value;
                  });
                  checkFinalSubmission(); 
                });
              }, 10000);
            }
          }
        // if (this.final_submission) {
        //   const ID = this.pretrial_data.findIndex(x => {
        //     const KEY = Object.keys(x);
        //     return KEY.includes("Les-final_brief") || KEY.includes("Les-final_submission");
        //   });
        //   this.posttrial_campaign_loader = true;
        //   if (ID !== -1) { 
        //     this.posttrial_campaign_loader = false; 
        //     this.posttrial_data.push(...this.pretrial_data.splice(ID, 1));
        //     this.posttrial_explanations.push(...this.explanations.splice(ID, 1));
        //   } 
        // }


        if (DATA.lesTrialCompletedPerCentage < 100) {
          this.trial_campaign_loader = true;
          this.trial_duration = DATA.lesTrialCompletedPerCentage;
          this.trial_timer = setInterval(() => {
            LES.GetLES(this.$route.params.id).then((res) => {
              const data = res.data.data;
              if (data.lesTrialCompletedPerCentage >= 100) {
                clearInterval(this.trial_timer);
                this.trial_duration = 100;
                data.lesTrialValues.forEach((element) => {
                  this.trial_data.push(element);
                });

                this.trial_explanations = this.trial_data.map((item) => {
                  const key = Object.keys(item)[0];
                  const value = JSON.parse(item[key]).explaination;
                  return value;
                });

                this.trial_campaign_loader = false;
              } else if (data.lesTrialCompletedPerCentage > 0) {
                this.trial_duration = data.lesTrialCompletedPerCentage;
              }
            });
          }, 10000);
        }
        
        const OutMessage = {
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        };
        const InMessage = {
          class_1: "chat__incoming",
          class_2: "chat__bubble__wrapper-incoming",
          class_3: "chat__bubble-incoming animated-background-incoming",
          new: false,
        };
        // LawBot Code
        this.mode = 'advanced';
        const filteredQuestions = DATA.questions.filter((element) => element.mode !== "advanced"); 
        filteredQuestions.forEach((element) => {
          if ((element.answer?.length ?? false) != 0) {
            this.default_questions.splice(
              this.default_questions[
                this.default_questions.findIndex((x) => x === element.question)
              ],
              1
            );

            OutMessage.text = element.question;
            this.chatMessages.push({...OutMessage});

            try {
              JSON.parse(element.answer).forEach((answer) => {
                InMessage.text = answer;
                this.chatMessages.push({...InMessage});
              });
            } catch (error) {
              InMessage.text = element.answer
              this.chatMessages.push({...InMessage});
            }
          }
        });

        if (
          DATA.questions.length != 0 &&
          DATA.questions[DATA.questions.length - 1].answer == null
        ) {
          this.loadAnswer = true;
          this.calltime("none");

          OutMessage.text = DATA.questions[
            DATA.questions.length - 1
          ].question;
          this.chatMessages.push(OutMessage);
        }

        if (this.chatMessages.length == 0) {
          InMessage.text = "You may ask question about the case here🔎";
          this.chatMessages.push(InMessage);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },

  destroyed() {
    clearInterval(this.interval);
    clearInterval(this.trial_timer);
    clearInterval(this.posttrialInterval);
    clearInterval(this.trialInterval);
  },

  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.trial_timer);
    clearInterval(this.posttrialInterval);
    clearInterval(this.trialInterval);
  },
};
</script>

<style scoped>
/* table */
.table {
  border: none;
  background: white;
  table-layout: fixed;
}

tbody {
  display: block;
  max-height: auto;
  overflow-y: scroll;
}

thead {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border-bottom: 0.1vw solid #ddd !important;
  background: white;
}

tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  overflow-x: auto;
  border: none;
  background: white;
}

td {
  border-bottom: 0.001px solid #ddd;
  border-top: none;
  vertical-align: top;
}

th {
  border: none;
  color: #094890;
}
/* table */

.container_margin {
  padding: 2rem;
}

.spinner {
  color: #f08000 !important;
}

.spinner-border {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-vertical-align: -0.15em;
  --bs-spinner-border-width: 0.2em;
}

.toggle__resultPhase {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.toggle__resultPhase input {
  display: none;
  translate: 2rem 0rem;
}

.toggle__resultPhase label {
  display: flex;
  justify-content: center;
  border: 3px solid #094890;
  width: 130px;
  height: 35px;
  font-weight: 600;
  /* text-transform: uppercase; */
  font-size: 17px;
  cursor: pointer;
  user-select: none;
}

.toggle__resultPhase input:checked + label {
  background-color: #094890;
}

.toggle__resultPhase input:checked + label span {
  color: #fff;
}

.toggle__resultPhase-preTrial {
  color: #094890;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right: 0px !important;
}

.toggle__resultPhase-Trial {
  color: #094890;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left: 0px !important;
}
.toggle__resultPhase-TrialDoc {
  color: #094890;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left: 0px !important;
  font-size: 16px !important;
}

.popUp_card {
  max-height: 600px !important;

  overflow-x: hidden;
  overflow-y: scroll;
}

/* chatBot Css */
.animated-background-outgoing {
  background: linear-gradient(
    45deg,
    #012741,
    #004877,
    #006bb3,
    #0866a5,
    #0976be
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.animated-background-incoming {
  background: linear-gradient(
    45deg,
    #cccaca,
    #bcbebe,
    #d4cece,
    #d3d4d4,
    #f1f1f1
  );
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.card_question {
  height: 586px;
}

.chat__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.chat__incoming {
  margin-right: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-incoming {
  margin-right: auto;
  margin-bottom: 1rem;
}

.chat__bubble-incoming {
  padding: 0.3rem 1rem;
  background-color: #f1f1f1;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  margin-right: 3rem;
  margin-left: 0.5rem;
}

.chat__bubble-incoming span {
  color: #333333;
}

.chat__outgoing {
  margin-left: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-outgoing {
  margin-left: auto;
  margin-bottom: 1rem;
}

.chat__bubble-outgoing {
  padding: 0.3rem 1rem;
  background-color: #86d4f8;
  border-radius: 30px;
  border-bottom-right-radius: 0px;
  margin-left: 3rem;
}

.chat__bubble-outgoing span {
  color: #fff;
}

/* chat suggestions and input */
.chat__suggestion {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding-left: 10px;
  height: auto;
  margin-bottom: 0.5rem;
}

.chat__suggestion:hover {
  background-color: #f1f1f1;
  transition: 300ms ease-out;
}

.chat__suggestion span {
  text-align: left;
  padding-top: 0.2rem;
}

.chat__input {
  display: flex;
  border: 2px solid #0058a2;
  border-radius: 20px;
  padding-left: 10px;
}

.chat__input input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0;
  margin: 0;
  height: 38px;
}

.chat__input .btn-send span {
  color: #0058a2;
  font-size: 40px;
}

.chat__input .btn-send:hover span {
  color: #004278;
  transition: 300ms ease-out;
}

.accordion-checkbox {
  display: none;
}

.accordion-label {
  cursor: pointer;
  display: block;
}

.accordion-icon {
  transition: transform 0.3s;
}

.accordion-checkbox:checked ~ .accordion-label .accordion-icon {
  transform: rotate(-180deg);
}

.card-header {
  padding: 0rem 0rem !important;
  padding-top: 1rem !important;
  margin-bottom: 0 !important;
  color: var(--bs-card-cap-color) !important;
  background-color: #ffffff !important;
  border-bottom: 0px solid #ffffff !important;
}

.content-card {
  height: 650px;
}

.underlined {
  border-bottom: 1px solid lightgray;
}

table tbody {
  height: 410px;
  overflow-y: scroll;
}
.toggle__resultPhase-preTrial.tabbutton-middle {
  border-radius: 0px;
  border-right: 0px !important;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.toggle__resultPhase-Trial.tabbutton-middle {
  border-radius: 0px;
  border-right: 0px !important;
}
.toggle__resultPhase-TrialDoc.tabbutton-middle {
  border-radius: 0px;
  border-right: 0px !important;
}
.toggle__resultPhase-postTrial{
  color: #094890;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left: 0px !important;
}
.toggle__resultPhase-postTrialRes{
  color: #094890;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-left: 0px !important;
}
.toggle__resultPhase-postTrialRes.tabbutton-middle {
  border-radius: 0px;
  border-right: 0px !important;
}
.toggle__resultPhase-postTrial.tabbutton-middle {
  border-radius: 0px;
  border-right: 0px !important;
}
</style>
